import React, { useRef, useState } from 'react';
import { Typography } from '@material-ui/core';
import styled from '@emotion/styled';
import { Document, Page } from 'react-pdf/dist/esm/entry.vite';

import {
  isImageDocument,
  isPdfDocument,
} from '_reconciliation/components/ReconciliationView/HiddenRow/HiddenGroupRow/utils/documentTypes';

import { PeriodDocument } from 'utils/usePeriodDocuments/Provider';

const ORIGINAL_PDF_HEIGHT = 792;
const PRINT_PDF_WIDTH = 784;

const Image = styled.img`
  break-inside: avoid;
  display: block;
  max-width: 100%;
  @media print {
    margin-top: ${(props) => props.theme.spacing(2)}px;
  }
`;

const ImageHeading = styled(Typography)`
  margin-top: ${(props) => props.theme.spacing(2)}px;
  @media print {
    display: none;
  }
`;

const PdfPreview = styled(Document)`
  width: 100%;
`;

const PageBreak = styled.a`
  page-break-before: always;
  page-break-after: always;
`;

const getFileExtension = (file) => {
  const dotIndex = file.name.lastIndexOf('.');
  let extension = '';

  if (dotIndex !== -1) {
    extension = file.name.substr(dotIndex);
  }

  return extension;
};

type DocumentProps = {
  document: PeriodDocument;
  isPrint?: boolean;
  onLoaded: () => void;
};

const DocumentView = ({
  document,
  isPrint = false,
  onLoaded,
}: DocumentProps) => {
  const pagesToLoad = useRef(0);

  const [numPages, setNumPages] = useState<{ [key: string]: number }>({});
  const [pageHeightDifference, setPageHeightDifference] = useState(0);

  const onPDFLoaded = async (pdf, id) => {
    const page = await pdf.getPage(1);
    const pageHeight = page.view[3]; // pdf page height accessible only from view instance;
    const pageWidth = page.view[2];
    /**
     * * Two times multiply because of the bug, that appears if pageHeight two times more than original pdf size;
     * TODO: Need to check with other PDF files (other size-types);
     * TODO: Create a validation for incorrect file size documents, while uploading PDF into app;
     */
    if (pageHeight > ORIGINAL_PDF_HEIGHT * 2) {
      const aspectRatio = pageWidth / PRINT_PDF_WIDTH;
      const calculatedPdfPageHeight = pageHeight / aspectRatio;
      setPageHeightDifference(calculatedPdfPageHeight);
    }
    setNumPages((currentNumPages) => ({
      ...currentNumPages,
      [id]: pdf.numPages,
    }));
    pagesToLoad.current += pdf.numPages;
  };

  const onPageRendered = () => {
    pagesToLoad.current -= 1;

    if (pagesToLoad.current === 0) {
      onLoaded();
    }
  };

  // Handle in onError files with extension not matching the type
  const extension = getFileExtension(document);

  if (!isImageDocument(extension) && !isPdfDocument(extension)) {
    return null;
  }

  if (isPrint) {
    return (
      <PageBreak href="https://agoy.se/page-break-marker">Page break</PageBreak>
    );
  }

  if (isImageDocument(extension)) {
    return (
      <>
        <ImageHeading>{document.name}</ImageHeading>
        <Image
          src={document.url}
          alt={document.name}
          onLoad={onLoaded}
          onError={onLoaded}
        />
      </>
    );
  }

  return (
    <>
      <ImageHeading>{document.name}</ImageHeading>
      <PdfPreview
        paddingBottom={pageHeightDifference}
        file={document.url}
        onLoadSuccess={(pdfInfo) => onPDFLoaded(pdfInfo, document.id)}
        onLoadError={onLoaded}
      >
        {Array.from(new Array(numPages[document.id] || 0), (el, index) => {
          return (
            <Page
              width={784}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              onRenderSuccess={onPageRendered}
              onRenderError={onPageRendered}
              renderAnnotationLayer={false}
              renderForms={false}
              renderTextLayer={false}
            />
          );
        })}
      </PdfPreview>
    </>
  );
};

export default DocumentView;
