import React, { useContext, useEffect, useState } from 'react';
import { IconButton, Paper } from '@material-ui/core';
import styled from '@emotion/styled';
import PrintIcon from '@material-ui/icons/Print';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import Typography from '_shared/components/Typography/Typography';
import { AccountPlanList, ReferencesTypes, VoucherSeries } from 'types/Voucher';
import TaxesDataContext from '_tax/service/TaxesDataContext';
import { asResultClass, useApiSdk } from 'api-sdk';
import { useSelector } from 'redux/reducers';
import { printDocument } from '_shared/components/PrintedDocument/utils';
import usePrintState from '_tax/hooks/usePrintState';
import { addGlobalErrorMessage } from 'redux/actions';

import AccrualFunds from './AccrualFunds';
import ReversalOfAccrualFund from './ReversalOfAccrualFund';
import AccrualFundDeposition from './AccrualFundDeposition';
import VerificationTable from './VerificationTable';
import PageContent from '../UI/PageContent';

import VoucherPrint from '../VoucherPrint';
import AdjustmentsPrintContext from '../Adjustments/AdjustmentsPrintContext';

const PrintButton = styled(IconButton)`
  &:hover {
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const Container = styled(Paper)`
  display: flex;
  flex-direction: column;
  padding: 32px;
  row-gap: 16px;
  min-width: 980px;

  @media print {
    min-width: unset;
    max-width: 980px;
    padding: 0;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AccrualFundContentPrint = () => {
  const { formatMessage } = useIntl();

  return (
    <Container>
      <Header>
        <Typography variant="h2" margin="bottom">
          {formatMessage({ id: 'tax.accruals' })}
        </Typography>
      </Header>
      <AccrualFunds />
      <AccrualFundDeposition print />
      <ReversalOfAccrualFund print />
      <PageContent>
        <VoucherPrint
          reference={ReferencesTypes.ACCRUAL_FUNDS}
          title={formatMessage({ id: 'tax.propertytax' })}
        />
      </PageContent>
    </Container>
  );
};

const AccrualFundPage = () => {
  const { formatMessage } = useIntl();
  const printState = usePrintState();
  const dispatch = useDispatch();
  const sdk = useApiSdk();

  const { clientId, period, financialYear } = useContext(TaxesDataContext);
  const { vouchersData } = useContext(AdjustmentsPrintContext);

  const [accountsList, setAccountsList] = useState<AccountPlanList[]>([]);
  const [seriesList, setSeriesList] = useState<VoucherSeries[]>([]);
  const [loading, setLoading] = useState(false);

  const integrationActive = useSelector(
    (state) => !!state.customers[clientId]?.integrations?.fortnox
  );

  useEffect(() => {
    const getVoucherSeries = async () => {
      const result = await asResultClass(
        sdk.getVoucherSeries({
          clientid: clientId,
          period,
        })
      );
      if (result.ok) {
        const series = result.val.map((item) => {
          return {
            series: item.series,
            label: `${item.series} - ${item.description}`,
          };
        });
        setSeriesList(series);
      }
    };
    getVoucherSeries();
  }, [period, clientId, sdk]);

  useEffect(() => {
    const getAccountsList = async () => {
      const source = integrationActive ? 'fortnox' : 'agoy';
      const result = await asResultClass(
        sdk.getAccountList({
          clientid: clientId,
          source,
        })
      );

      if (result.ok) {
        const accounts = result.val.map((item) => {
          return {
            value: item.number,
            label: item.name,
            noActive: !item.active,
          };
        });

        setAccountsList([
          { value: 0, label: ' ', noActive: false },
          ...accounts,
        ]);
      }
    };
    getAccountsList();
  }, [clientId, integrationActive, sdk]);

  const handlePrint = async () => {
    try {
      setLoading(true);
      await printDocument(
        clientId,
        financialYear,
        'taxes',
        formatMessage({ id: 'tax.accruals' }),
        ['accrualFund'],
        { ...printState, adjustmentsData: vouchersData },
        vouchersData[ReferencesTypes.ACCRUAL_FUNDS]?.documents?.map(
          (item) => item.url
        )
      );
    } catch (e) {
      dispatch(addGlobalErrorMessage('error'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Header>
        <Typography variant="h2" margin="none">
          {formatMessage({ id: 'tax.accruals' })}
        </Typography>
        <PrintButton disabled={loading} onClick={handlePrint}>
          <PrintIcon />
        </PrintButton>
      </Header>
      <AccrualFunds />
      <AccrualFundDeposition />
      <ReversalOfAccrualFund />
      <PageContent>
        <VerificationTable
          accountsList={accountsList}
          seriesList={seriesList}
        />
      </PageContent>
    </Container>
  );
};

export default AccrualFundPage;
