import React, {
  useContext,
  useEffect,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { asResultClass, useApiSdk } from 'api-sdk';
import { useParams } from 'react-router-dom';

import { ClientsRouteParams } from 'routes/types';
import { FinancialYear, Period } from '@agoy/api-sdk-core';
import { UserInput } from '_reconciliation/types';
import { PrintContext } from '_shared/HOC/withPrintContext';
import { AccountingBalances } from 'types/Accounting';
import SharpPDFToggler from '_shared/components/SharpPDFToggler';
import CommonPreviewModal from '_shared/components/CommonPreviewModal';
import getFileExtension from 'utils/fileExtension';
import { PeriodDocument } from 'utils/usePeriodDocuments/Provider';
import { printDocument } from '_shared/components/PrintedDocument/utils';
import { parseFormat } from '@agoy/dates';
import { useSelector } from 'redux/reducers';

import { BalanceAndInput } from '../types';
import {
  isImageDocument,
  isPdfDocument,
} from '../HiddenRow/HiddenGroupRow/utils/documentTypes';
import PrintSpecificationsContent from './PrintSpecificationsContent';
import PeriodDataContext from '../HiddenRow/Rows/PeriodDataContext';

const TopBarActions = styled.div`
  display: flex;
  justify-content: space-between;
  @media print {
    display: none;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  @media print {
    display: none;
  }
`;

type PrintSpecificationsModalProps = {
  open: boolean;
  checked: boolean;
  period: Period;
  financialYear: FinancialYear;
  accountingBalances: AccountingBalances;
  userInputData: UserInput;
  actualFinancialYear: string;
  balanceAndInput: BalanceAndInput[];
  handleToggleModal: () => void;
  reload: () => void;
};

const PrintSpecificationsModal = ({
  open,
  checked,
  period,
  actualFinancialYear,
  financialYear,
  accountingBalances,
  userInputData,
  balanceAndInput,
  handleToggleModal,
  reload,
}: PrintSpecificationsModalProps): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const sdk = useApiSdk();

  const { clientId } = useParams<ClientsRouteParams>();

  const { setHideMainContent } = useContext(PrintContext);
  const periodData = useContext(PeriodDataContext);

  const accountingView = useSelector((state) => state.accountingView);
  const customer = useSelector((state) => state.customers[clientId]);

  const [documentsByAccount, setDocumentsByAccount] = useState<
    Record<string, PeriodDocument[]>
  >({});
  const [documentsLoaded, setDocumentsLoaded] = useState(0);
  const [documentsToLoad, setDocumentsToLoad] = useState(0);
  const [printLoading, setPrintLoading] = useState(false);

  const isLoading = documentsToLoad - documentsLoaded > 0;

  const accounts = useMemo(
    () =>
      Object.keys(userInputData)
        .filter((key) => {
          if (!key.startsWith('account')) {
            return false;
          }

          const accountData = accountingBalances.accounts.find(
            (a) => a.number === key.substring(7)
          );

          return (
            userInputData[key][period.id] &&
            Object.keys(userInputData[key][period.id]).length > 0 &&
            accountData
          );
        })
        .map((key) => key.substring(7))
        .sort((a, b) => Number(a) - Number(b)),
    [accountingBalances.accounts, period.id, userInputData]
  );

  const getAccountsDocuments = useCallback(async () => {
    const result = await asResultClass(
      sdk.getPeriodDocuments({
        clientid: clientId,
        account: accounts,
        periodId: [period.id],
      })
    );

    if (result.ok) {
      const filteredDocuments = result.val.filter((item) => {
        const extension = getFileExtension(item.name);
        // show only pdf and pictures
        return isImageDocument(extension) || isPdfDocument(extension);
      });

      const documents: Record<string, PeriodDocument[]> = {};

      filteredDocuments.forEach((item) => {
        item.references.forEach((reference) => {
          if (reference.type === 'A') {
            documents[reference.key] = [
              ...(documents[reference.key] || []),
              { ...item, period },
            ];
          }
        });
      });

      setDocumentsByAccount(documents);
      setDocumentsToLoad(filteredDocuments.length);
    }

    return {};
  }, [accounts, clientId, period, sdk]);

  useEffect(() => {
    getAccountsDocuments();
  }, [getAccountsDocuments]);

  useEffect(() => {
    setHideMainContent(open);
  }, [open, setHideMainContent]);

  useEffect(() => {
    if (!open) {
      setDocumentsLoaded(0);
      setDocumentsToLoad(0);
    }
  }, [open]);

  const handleDocumentLoaded = useCallback(() => {
    setDocumentsLoaded((currentValue) => currentValue + 1);
  }, []);

  const handleChange = useCallback(() => {
    reload();
  }, [reload]);

  const handlePrint = useCallback(async () => {
    setPrintLoading(true);

    const financialYearPeriod = `${parseFormat(
      financialYear.start,
      'yyyyMM'
    )}-${parseFormat(financialYear.end, 'yyyyMM')}`;
    const attachments = accounts.reduce((acc, account) => {
      if (documentsByAccount[account]?.length) {
        return [...acc, ...documentsByAccount[account].map((doc) => doc.url)];
      }
      return acc;
    }, [] as string[]);

    try {
      await printDocument(
        clientId,
        financialYearPeriod,
        'accounting',
        `${formatMessage({ id: 'hidden.specification.view' })} ${period.start}`,
        ['specifications'],
        {
          accountingView,
          periodData,
          customers: { [clientId]: customer },
          specificationsData: {
            clientId,
            accounts,
            documentsByAccount,
            period,
            actualFinancialYear,
            financialYear,
            accountingBalances,
            userInputData,
            balanceAndInput,
          },
        },
        attachments
      );
    } finally {
      setPrintLoading(false);
    }
  }, [
    accountingBalances,
    accountingView,
    accounts,
    actualFinancialYear,
    balanceAndInput,
    clientId,
    customer,
    documentsByAccount,
    financialYear,
    formatMessage,
    period,
    periodData,
    userInputData,
  ]);

  return (
    <CommonPreviewModal
      handleShow={handleToggleModal}
      onPrint={handlePrint}
      isOpen={open}
      loading={isLoading || printLoading}
    >
      <TopBarActions>
        <LoadingContainer>
          {isLoading &&
            formatMessage(
              {
                id: 'print.periodSpecification.loading',
              },
              {
                value: documentsLoaded,
                valueOf: documentsToLoad,
              }
            )}
        </LoadingContainer>
        <ButtonWrapper>
          <SharpPDFToggler checked={checked} onChange={handleChange} />
        </ButtonWrapper>
      </TopBarActions>
      {open && (
        <PrintSpecificationsContent
          clientId={clientId}
          period={period}
          actualFinancialYear={actualFinancialYear}
          financialYear={financialYear}
          accountingBalances={accountingBalances}
          userInputData={userInputData}
          balanceAndInput={balanceAndInput}
          accounts={accounts}
          documentsByAccount={documentsByAccount}
          handleDocumentLoaded={handleDocumentLoaded}
        />
      )}
    </CommonPreviewModal>
  );
};

export default PrintSpecificationsModal;
