import React, { useEffect } from 'react';
import { initWebAnalytics, trackPageView } from '@fnox/web-analytics-script';
import { useLocation } from 'react-router-dom';
import config from '_shared/services/config';

const washIt = (url) => {
  // replace UUID with guid (Fortnox use the term guid instead of UUID)
  const uuidPattern =
    /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi;
  return url.replace(uuidPattern, 'guid');
};

const withWebAnalytics = (Component) => (props) => {
  const location = useLocation();

  useEffect(() => {
    if (!config.piwikKey) {
      // No key configured no need to initialize
      return;
    }

    const cookieDomain =
      config.runningEnvironment === 'development' ? 'localhost' : '.agoy.se';

    initWebAnalytics({
      washViewCallback: washIt,
      applicationId: config.piwikKey,
      cookieDomain,
    });
  }, []);

  useEffect(() => {
    const path = `${window.location.origin}${location.pathname}`;

    trackPageView({
      view: path,
    });
  }, [location]);

  return <Component {...props} />;
};

export default withWebAnalytics;
